// @ts-ignore
import { withRouter } from 'next/router'
import SingletonRouter from 'next/router'
import * as React from 'react'

import RawFormattedMessage from './RawFormattedMessage'
import styles from './HTTPError.module.css'

const assetPrefix = process.env.ASSET_PREFIX || ''

function pathnameToPrefix (prefix: string): string {
  switch (prefix) {
    case '/404':
      return 'not-found'
    case '/403':
      return 'not-authorized'
    default:
      return 'not-found'
  }
}

export class HTTPError extends React.Component<{ router: SingletonRouter }> {
  render () {
    const prefix = pathnameToPrefix(this.props.router.pathname)
    return (
      <section id={prefix} className={styles.root} data-qa='http-error.section'>
        <header>
          <RawFormattedMessage tagName='h4' id={`${prefix}.title`} />
        </header>
        <RawFormattedMessage
          tagName='p'
          className={styles.text}
          id={`${prefix}.text`}
        />
        <a href={`${assetPrefix}/`} data-qa='back-to-homepage'>
          <RawFormattedMessage id={`${prefix}.home-page`} />
        </a>
      </section>
    )
  }
}

export default withRouter(HTTPError)
